import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams, Link } from "react-router-dom";
import Logo from "../assets/logo.jpg";
import { FaCheckCircle } from "react-icons/fa";

const EmailVerification = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get("token");

      if (!token) {
        setError("Verification token is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/verify-email`,
          {
            params: { token },
          }
        );

        if (response.status === 200) {
          setMessage("Your email has been successfully verified!");
        } else {
          setError("Unexpected response from the server.");
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 400) {
            setError("The verification token is invalid or expired.");
          } else {
            setError(`Error: ${err.response.data.message}`);
          }
        } else {
          setError("System Maintenance in Progress. Please try again later.");
        }
        console.error("Verification error:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchParams.get("token")) {
      verifyEmail();
    } else {
      setIsLoading(false);
    }
  }, [searchParams]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] p-6">
      <div className="w-full max-w-md bg-[#02273D] p-8 rounded-lg shadow-md">
        <div className="flex flex-col items-center mb-6">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="text-center">
          {isLoading && <p className="text-gray-300 text-lg">Verifying...</p>}
          {message && (
            <div className="flex flex-col items-center">
              <FaCheckCircle className="text-green-600 text-5xl mb-4" />
              <p className="text-green-600 text-lg mb-4">{message}</p>
              <p className="text-gray-300 mb-4">
                Your email address has been successfully verified. You can now
                access all features of your account.
              </p>
              <p className="text-gray-300 mb-4">
                If you need further assistance or have any questions, please{" "}
                <Link to="/support" className="text-blue-400 hover:underline">
                  contact our support team
                </Link>
                .
              </p>
              <p className="text-gray-300">
                <Link to="/login" className="text-blue-400 hover:underline">
                  Log in
                </Link>{" "}
                to your account to get started.
              </p>
            </div>
          )}
          {error && <p className="text-red-600 text-lg">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
