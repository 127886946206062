import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.jpg";
import { useAuth } from "../utils/AuthProvide";
Modal.setAppElement("#root");

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { authenticatedAdmin } = useAuth();

  useEffect(() => {
    if (!authenticatedAdmin) {
      navigate("/");
    }
  }, [authenticatedAdmin, navigate]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          withCredentials: true,
        }
      );
      if (Array.isArray(response.data)) {
        setUsers(response.data);
      } else if (typeof response.data === "object" && response.data !== null) {
        setUsers([response.data]);
      } else {
        setError("Unexpected API response format");
      }
    } catch (err) {
      setError("Failed to fetch users");
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDelete = (userId) => {
    setSelectedUser(userId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/${selectedUser}`,
        {
          withCredentials: true,
        }
      );

      setMessage("User deleted successfully");
      fetchUsers();
    } catch (err) {
      setError("Failed to delete user");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${selectedUser.id}`,
        selectedUser,
        {
          withCredentials: true,
        }
      );
      setMessage("User updated successfully");
      fetchUsers();
    } catch (err) {
      setError("Failed to update user");
    } finally {
      setShowEditModal(false);
    }
  };
  useEffect(() => {
    let timer;
    if (message || error) {
      timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 4000);
    }
    return () => clearTimeout(timer);
  }, [message, error]);

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;

    setSelectedUser({ ...selectedUser, [name]: value });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <header className="bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <img src={logo} alt="Logo" className="h-24" />
        </div>
      </header>
      <main className=" mx-auto p-4">
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {message && <p className="text-green-600 mb-4">{message}</p>}

        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Title
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Contact Method
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Telephone
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Purpose
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Help
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Verified
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Permission Level
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-600 tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.id}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.username}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.name}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.title}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.email}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.contactmethod}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.telephone}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.purpose}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.help}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.verified ? "Yes" : "No"}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.permission_level}
                    </td>
                    <td className="px-6 whitespace-no-wrap border-b border-gray-500">
                      {user.status}
                    </td>
                    <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-500">
                      <button
                        onClick={() => handleEdit(user)}
                        className="bg-green-500 text-white py-1 px-3 rounded mr-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="11"
                    className="px-6 whitespace-no-wrap border-b border-gray-500 text-center"
                  >
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Edit User Modal */}
        <Modal
          isOpen={showEditModal}
          onRequestClose={() => setShowEditModal(false)}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div className="bg-white p-6 rounded-lg w-full max-w-2xl ">
            <h2 className="text-2xl font-semibold mb-4">Edit User</h2>
            {selectedUser && (
              <form
                onSubmit={handleSubmitEdit}
                className="space-y-4 grid grid-cols-3 gap-4"
              >
                <div>
                  <label className="block text-gray-700">Username:</label>
                  <input
                    type="text"
                    name="username"
                    value={selectedUser.username || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={selectedUser.name || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Title:</label>
                  <input
                    type="text"
                    name="title"
                    value={selectedUser.title || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={selectedUser.email || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Contact Method:</label>
                  <input
                    type="text"
                    name="contactmethod"
                    value={selectedUser.contactmethod || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Telephone:</label>
                  <input
                    type="text"
                    name="telephone"
                    value={selectedUser.telephone || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="col-span-3">
                  <label className="block text-gray-700">Purpose:</label>
                  <input
                    type="text"
                    name="purpose"
                    value={selectedUser.purpose || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="col-span-3">
                  <label className="block text-gray-700">Help:</label>
                  <input
                    type="text"
                    name="help"
                    value={selectedUser.help || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Verified:</label>
                  <select
                    name="verified"
                    value={selectedUser.verified ? "1" : "0"}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">Status </label>
                  <input
                    type="number"
                    name="status"
                    value={selectedUser.status || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    Permission Level:
                  </label>
                  <input
                    type="number"
                    name="permission_level"
                    value={selectedUser.permission_level || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setShowEditModal(false)}
                    className="bg-gray-500 text-white py-2 px-4 rounded mr-2 hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal>

        {/* Delete User Modal */}
        <Modal
          isOpen={showDeleteModal}
          onRequestClose={() => setShowDeleteModal(false)}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this user?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleConfirmDelete}
                className="bg-red-500 text-white py-2 px-4 rounded mr-2 hover:bg-red-600"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default AdminPanel;
