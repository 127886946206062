import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthProvide";
import Cookies from "js-cookie";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedUrl, setSelectedUrl] = useState("");

  const { authenticatedUser, setAuthenticatedUser } = useAuth();

  const handleUrlChange = (name) => {
    setSelectedUrl(name);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    setAuthenticatedUser(false);
    navigate("/login");
  };

  return (
    <header className="bg-[#003B5C] border-b-2 border-gray-600 text-white py-4 fixed top-0 right-0 w-full z-10 px-10">
      <div className="flex justify-end space-x-4">
        <Link
          to="/"
          className={`hover:text-gray-400 transition-transform transform ${
            location.pathname === "/" ? "underline scale-105 " : ""
          }`}
          onClick={() => handleUrlChange("/")}
        >
          Home
        </Link>
        <Link
          to="/help"
          className={`hover:text-gray-400 transition-transform transform ${
            location.pathname === "/help" ? "underline scale-105" : ""
          }`}
          onClick={() => handleUrlChange("/help")}
        >
          Help
        </Link>
        {authenticatedUser ? (
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md"
          >
            Logout
          </button>
        ) : (
          <Link
            to="/login"
            className={`hover:text-gray-400 transition-transform transform ${
              location.pathname === "/login" ? "underline scale-105 " : ""
            }`}
            onClick={() => handleUrlChange("/login")}
          >
            Login
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
