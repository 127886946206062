import React, { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [authenticatedAdmin, setAuthenticatedAdmin] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const isAdmin = decodedToken.isAdmin || false;
        console.log(decodedToken.isAdmin);

        if (isAdmin) {
          setAuthenticatedAdmin(true);
        } else {
          setAuthenticatedUser(true);
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
        setAuthenticatedUser(false);
        setAuthenticatedAdmin(false);
      }
    } else {
      setAuthenticatedUser(false);
      setAuthenticatedAdmin(false);
    }
  }, []);

  const setAuthenticatedUserWithToken = (value, token = null) => {
    if (value && token) {
      try {
        const decodedToken = jwtDecode(token);
        const isAdmin = decodedToken.isAdmin || false;

        Cookies.set("token", token, { expires: 1 }); // 1 day expiration
        console.log("Token set:", token);

        if (isAdmin) {
          setAuthenticatedAdmin(true);
          // setAuthenticatedUser(false);
        } else {
          setAuthenticatedUser(true);
          // setAuthenticatedAdmin(false);
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
        setAuthenticatedUser(false);
        setAuthenticatedAdmin(false);
      }
    } else {
      Cookies.remove("token");
      console.log("Token removed");
      setAuthenticatedUser(false);
      setAuthenticatedAdmin(false);
    }
  };

  const value = {
    isRegistered,
    setIsRegistered,
    emailVerified,
    setEmailVerified,
    authenticatedAdmin,
    setAuthenticatedAdmin: setAuthenticatedUserWithToken,
    authenticatedUser,
    setAuthenticatedUser: setAuthenticatedUserWithToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
