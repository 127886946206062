import React from "react";

const Footer = () => {
  return (
    <footer className="border-t-2 border-gray-600 bg-[#003B5C] text-white text-center py-4 fixed bottom-0 w-full">
      <p className="text-sm">
       Copyright &copy; 2024 FolioSynth LLC - All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
