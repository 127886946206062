import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/logo.jpg";
import { useAuth } from "../utils/AuthProvide";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setAuthenticatedUser } = useAuth();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        { username, password },
        { withCredentials: true }
      );

      if (response.status === 200) {
        setMessage("Login successful!");
        setAuthenticatedUser(true, response.data.token);
        navigate("/landing");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError("System Maintenance in Progress. Please try again later.");
      }
    }
  };

  const handleForgotPassword = () => {
    navigate("/account_recover");
  };

  const handleRegister = () => {
    navigate("/signup");
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] p-6">
      <div className="w-full max-w-md bg-transparent border border-gray-600 p-8 rounded-lg shadow-md">
        <div className="flex flex-col items-center mb-6">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-200"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-200"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          {error && <p className="text-red-500 text-md mb-4">{error}</p>}
          {message && <p className="text-green-600 text-sm mb-4">{message}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Login
          </button>
          <div className="flex justify-between mt-4">
            <div>
              <button
                type="button"
                onClick={handleForgotPassword}
                className="text-gray-300 text-sm"
              >
                Forgot Username or Password?
              </button>
            </div>
            <div className="text-sm ">
              <button
                type="button"
                onClick={handleRegister}
                className="px-2 text-blue-400"
              >
                Sign-up
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
